import {omit} from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import {
  BrandSafetyAnalysis,
  PostAnalysis,
  PostAnalysisFailure,
} from '@/types/creatorSafetyReport';
import useFittedElementsInContainer from '@/hooks/use-fitted-elements-in-container';
import {
  creatorVettingReportActions,
  ReportSettings,
} from '@/stores/creatorVettingReportStore';
import {FAILED_SCAN_FLAG} from '../../constants';
import getSeverityFromFlagScore from '../../utils/getSeverityFromFlagScore';
import styles from './ProfileVettingContentItemPosts.module.scss';

const MAX_MINIMIZED_DISPLAYED_POSTS_AMOUNT = 5;
const IMAGE_WIDTH = 140;
const GAP = 16;

interface ContentItemPostProps {
  flag: string;
  post: PostAnalysis | PostAnalysisFailure;
  hoveredPostId?: string | null;
  remainingPosts: number;
  showSeverityIndicator: boolean;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

function SeverityIndicator(props: {score: number}) {
  const {score} = props;
  const severity = getSeverityFromFlagScore(score);
  return <div className={`${styles.severityIndicator} ${styles[severity]}`} />;
}

function ContentItemPost(props: ContentItemPostProps) {
  const {
    flag,
    post,
    hoveredPostId,
    remainingPosts,
    showSeverityIndicator,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const getFlagScore = () => {
    if (flag === FAILED_SCAN_FLAG) {
      return 0;
    }
    const analysisWithoutSummary = omit(
      (post as PostAnalysis).mediaAnalysisResult.brandSafetyAnalysis,
      ['summary']
    );
    return analysisWithoutSummary?.[
      flag as keyof Omit<BrandSafetyAnalysis, 'summary'>
    ]?.score;
  };

  const getPostUrl = (postAnalysis: PostAnalysis | PostAnalysisFailure) => {
    if ('mediaAnalysisResult' in postAnalysis) {
      return postAnalysis.mediaAnalysisResult.metadata.url;
    }
    return postAnalysis.mediaMetadata.url;
  };

  return (
    <div className={styles.postContainer}>
      <div
        className={`${styles.contentItemPost} ${
          hoveredPostId === post.id ? styles.hover : ''
        }`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {remainingPosts > 0 ? (
          <div className={styles.overlay}>
            <Label size="lg" color={designSystemToken('semantic.fg.white')}>
              +{remainingPosts}
            </Label>
          </div>
        ) : null}
        <img src={getPostUrl(post)} alt="post" />
      </div>
      {showSeverityIndicator ? (
        <SeverityIndicator score={getFlagScore()} />
      ) : null}
    </div>
  );
}

function ProfileVettingContentItemPosts(props: {
  posts: PostAnalysis[] | PostAnalysisFailure[];
  flag: string;
  report: ReportSettings;
  seeAll: boolean;
  setSeeAll: (seeAll: boolean) => void;
  onPostClick: (flag: string, postId: string) => void;
}) {
  const {posts, flag, report, seeAll, setSeeAll, onPostClick} = props;

  const postsContainerRef = React.useRef<HTMLDivElement>(null);

  const maxMinimizedDisplayedPostsAmount = useFittedElementsInContainer(
    postsContainerRef,
    IMAGE_WIDTH,
    GAP,
    MAX_MINIMIZED_DISPLAYED_POSTS_AMOUNT
  );

  const displayedPosts = seeAll
    ? posts
    : posts.slice(0, maxMinimizedDisplayedPostsAmount);
  const remainingPosts = posts.length - displayedPosts.length;

  useEffect(() => {
    if (displayedPosts.length < maxMinimizedDisplayedPostsAmount && seeAll) {
      setSeeAll(false);
    }
  }, [displayedPosts, maxMinimizedDisplayedPostsAmount]);

  const getShowSeverityIndicator = useCallback(
    (index: number) => {
      if (flag === FAILED_SCAN_FLAG) {
        return false;
      }
      if (seeAll || remainingPosts === 0) {
        return true;
      }
      return index + 1 !== maxMinimizedDisplayedPostsAmount;
    },
    [seeAll, remainingPosts, flag]
  );

  return (
    <div className={styles.profileVettingContentItemPostsContainer}>
      <div className={styles.postsContainer} ref={postsContainerRef}>
        {displayedPosts.map((post, index) => (
          <ContentItemPost
            key={post.id}
            flag={flag}
            post={post}
            hoveredPostId={report?.hoveredPostId}
            remainingPosts={
              !seeAll &&
              index + 1 === maxMinimizedDisplayedPostsAmount &&
              remainingPosts > 0
                ? remainingPosts
                : 0
            }
            showSeverityIndicator={getShowSeverityIndicator(index)}
            onClick={() => {
              if (index === displayedPosts.length - 1 && remainingPosts > 0) {
                setSeeAll(true);
                if (report?.id) {
                  creatorVettingReportActions.setSeeAllFlagPosts(
                    report?.id,
                    flag,
                    true
                  );
                }
              } else {
                onPostClick(flag, post.id);
              }
            }}
            onMouseEnter={() => {
              if (report?.id) {
                creatorVettingReportActions.setHoveredPostId(
                  report?.id,
                  post.id
                );
              }
            }}
            onMouseLeave={() => {
              if (report?.id) {
                creatorVettingReportActions.setHoveredPostId(report?.id, null);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ProfileVettingContentItemPosts;
