import React from 'react';
import {Box} from '@mui/material';
import {PostAnalysis} from '@/types/creatorSafetyReport';
import styles from './PostPanel.module.scss';

export interface MediaPanelProps {
  testID?: string;
  post: PostAnalysis;
}

function PostPanel(props: MediaPanelProps) {
  const {testID = 'post-panel', post} = props;
  const src = post.mediaAnalysisResult.metadata.url;
  return (
    <Box className={styles.mediaPanelContainer} data-testid={testID}>
      <Box className={styles.postImageContainer}>
        <img src={src} alt="post" className={styles.postImage} />
      </Box>
    </Box>
  );
}

export default PostPanel;
