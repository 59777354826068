import {capitalize} from 'lodash';

export default function formatCamelCaseString(str: string) {
  if (!str) return '';
  return capitalize(
    str
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
      .trim()
      .toLowerCase()
  );
}
