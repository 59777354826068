import React from 'react';
import {Box} from '@mui/material';
import {PostAnalysis} from '@/types/creatorSafetyReport';
import styles from './PostModalContent.module.scss';
import AnalysisPanel from './analysis-panel';
import PostPanel from './post-panel';

export interface PostModalContentProps {
  testID?: string;
  post: PostAnalysis;
  category: string;
}

function PostModalContent(props: PostModalContentProps) {
  const {testID = 'post-modal-content', post, category} = props;
  return (
    <Box className={styles.postModalContentContainer} data-testid={testID}>
      <Box className={styles.postContainer}>
        <Box className={styles.postPanelWrapper}>
          <PostPanel post={post} />
        </Box>
      </Box>
      <Box className={styles.analysisContainer}>
        <AnalysisPanel post={post} category={category} />
      </Box>
    </Box>
  );
}

export default PostModalContent;
