import dayjs from 'dayjs';
import React from 'react';
import {
  designSystemToken,
  Headline,
  Label,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {US_SHORT_DAY_SHORT_MONTH_YEAR_FORMAT_WITH_SEPARATOR} from '@/config/timeFormats';
import Conditional from '@/components/conditional';
import Link from '@/components/link';
import SocialNetworkIcon from '@/components/social-network-icon';
import styles from './ScannedPlatforms.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.scanned-platforms';

interface Platform {
  id: string;
  handle: string;
  url: string;
  disconnectDate: Date | null;
  trackAction?: (actionName: string, actionValue: string) => void;
}

interface ScannedPlatformsProps {
  platforms: Platform[];
  trackAction: (actionName: string, actionValue: string) => void;
}

const BACKGROUND_CHECK = {
  id: 'google',
  handle: 'Found on Google',
  url: '',
  disconnectDate: null,
};

const SOCIAL_NETWORK_ICON_SIZE = 24;

function ScannedPlatformIcon({id}: {id: string}) {
  return (
    <div className={styles.scannedPlatformIconContainer}>
      <SocialNetworkIcon
        name={id}
        width={SOCIAL_NETWORK_ICON_SIZE}
        height={SOCIAL_NETWORK_ICON_SIZE}
      />
    </div>
  );
}

function ScannedPlatform(props: Platform) {
  const {id, handle, disconnectDate, url, trackAction} = props;

  const getDisconnectDate = (date: Date) => {
    return (
      <Label size="md" color={designSystemToken('semantic.fg.secondary')}>
        {translate(`${TRANSLATION_PREFIX}.creator-disconnected`, {
          date: dayjs(date).format(
            US_SHORT_DAY_SHORT_MONTH_YEAR_FORMAT_WITH_SEPARATOR
          ),
        })}
      </Label>
    );
  };

  const getHandleLabel = () => {
    const label = (
      <Label size="lg" color={designSystemToken('semantic.fg.primary')}>
        {handle}
      </Label>
    );
    if (url) {
      return (
        <Link
          keepBrandIdIfPresent={false}
          to={url}
          target="_blank"
          onClick={() => trackAction?.('Open social network page', url)}
        >
          {label}
        </Link>
      );
    }
    return label;
  };

  return (
    <div className={styles.scannedPlatform}>
      <div className={styles.platformIcon}>
        <ScannedPlatformIcon id={id} />
      </div>
      <div className={styles.platformInfo}>
        <div className={`${styles.handle} ${url ? styles.url : ''}`}>
          {getHandleLabel()}
        </div>
        <Conditional condition={!!disconnectDate}>
          {getDisconnectDate(disconnectDate as Date)}
        </Conditional>
      </div>
    </div>
  );
}

function ScannedPlatforms(props: ScannedPlatformsProps) {
  const {platforms, trackAction} = props;
  const platformsWithBackgroundCheck = platforms.concat(BACKGROUND_CHECK);
  return (
    <div className={styles.scannedPlatformsContainer}>
      <Headline size="sm">{translate(`${TRANSLATION_PREFIX}.title`)}</Headline>
      <div className={styles.platformsContainer}>
        {platformsWithBackgroundCheck.map((platform) => (
          <ScannedPlatform
            key={platform.id}
            {...platform}
            trackAction={trackAction}
          />
        ))}
      </div>
    </div>
  );
}

export default ScannedPlatforms;
