import dayjs from 'dayjs';
import {produce} from 'immer';
import React, {useEffect, useState} from 'react';
import {designSystemToken, Headline} from '@lightricks/react-design-system';
import {FLOW_NAMES} from '@/lib/analytics/analyticsConstants';
import camelCaseToDisplayName from '@/utils/camelCaseToDisplayName';
import translate from '@/utils/translate';
import {NUMERIC_DAY_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import {CreatorSafetyReport, PostAnalysis} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import useGetVettingCategories from '@/components/creator-vetting-report/hooks/use-get-vetting-categories';
import useGetVettingFilters from '@/components/creator-vetting-report/hooks/use-get-vetting-filters';
import Divider from '@/components/divider';
import ProfileLink from '@/components/profile-link';
import useTrackBrandSafetyCreatorActionEvent from '@/hooks/analytics/use-track-brand-safety-creator-action-event/useTrackBrandSafetyCreatorActionEvent';
import useTrackBrandSafetyCreatorEvent from '@/hooks/analytics/use-track-brand-safety-creator-event';
import useParams from '@/hooks/use-params';
import {creatorVettingReportActions} from '@/stores/creatorVettingReportStore';
import ModalNavigation from '../modal-navigation';
import styles from './CreatorVettingReport.module.scss';
import PostModalContent from './components/post-modal-content';
import PostModalTitle from './components/post-modal-content/post-modal-title';
import ProfileVettingActions from './components/profile-vetting-actions';
import ProfileVettingBreadcrumbs from './components/profile-vetting-breadcrumbs';
import ProfileVettingFailedContent from './components/profile-vetting-failed-content';
import ProfileVettingFlaggedContent from './components/profile-vetting-flagged-content';
import ProfileVettingInfo from './components/profile-vetting-info';
import ProfileVettingItemsFlagged from './components/profile-vetting-items-flagged';
import ProfileVettingItemsScanned from './components/profile-vetting-items-scanned';
import ProfileVettingSummary from './components/profile-vetting-summary';
import ProfileVettingTracking from './components/profile-vetting-tracking';
import ScannedPlatforms from './components/scanned-platforms';
import {Severity} from './constants';

const TRANSLATION_PREFIX = 'components.creator-vetting-report';

export interface CreatorVettingReportProps {
  testID?: string;
  vettingReport: CreatorSafetyReport; // TODO: define correct type
  showBackButton?: boolean;
  showProfileInfo?: boolean;
}

type PostModalData = {
  posts: PostAnalysis[];
  flag: string;
  selectedPostIndex: number;
};

function SectionTitle(props: {
  title: string | React.ReactNode;
  actions?: React.ReactNode;
}) {
  const {title, actions} = props;
  return (
    <div className={styles.sectionTitle}>
      {typeof title === 'string' ? (
        <Headline size="xl">{title}</Headline>
      ) : (
        title
      )}
      <div className={styles.actions}>{actions}</div>
    </div>
  );
}

function CreatorVettingReport(props: CreatorVettingReportProps) {
  const {
    testID = 'creator-vetting-report',
    vettingReport,
    showBackButton = true,
    showProfileInfo = true,
  } = props;

  const trackBrandSafetyCreatorEvent = useTrackBrandSafetyCreatorEvent();
  const trackBrandSafetyCreatorActionEvent =
    useTrackBrandSafetyCreatorActionEvent(FLOW_NAMES.BRAND_SAFETY_CREATOR);

  useEffect(() => {
    const creatorPayload = {
      creator_id: vettingReport.creatorId,
      creator_name: vettingReport.creatorName,
    };
    trackBrandSafetyCreatorEvent.presented({
      ...creatorPayload,
    });

    return () => {
      trackBrandSafetyCreatorEvent.dismissed({
        ...creatorPayload,
      });
    };
  }, []);

  const trackAction = (actionName: string, actionValue: string) => {
    trackBrandSafetyCreatorActionEvent.action({
      brand_safety_screen_name: 'Vetting Report',
      creator_id: vettingReport.creatorId,
      action_name: actionName,
      action_value: actionValue,
    });
  };

  const {reportId} = useParams();

  const vettingFilters = useGetVettingFilters(vettingReport.timeRange);

  const {postAnalysesStats} = vettingReport;
  const vettingCategories = useGetVettingCategories(
    vettingReport.flaggedPostAnalyses,
    vettingReport.failedPostAnalyses,
    {
      timeRange: {
        start: vettingFilters.dateRange.startDate,
        end: vettingFilters.dateRange.endDate,
      },
      severity: vettingFilters.severity,
    }
  );

  const [liveTrackingProxy, setLiveTrackingProxy] = React.useState(
    vettingReport.liveTracking
  );

  const [vettingStatusProxy, setVettingStatusProxy] = React.useState(
    vettingReport.status
  );

  const [showModal, setShowModal] = useState(false);
  const [postModalData, setPostModalData] = useState<PostModalData>({
    posts: [],
    flag: '',
    selectedPostIndex: 0,
  });

  const nextPostClickHandler = () => {
    setPostModalData(
      produce(postModalData, (draft) => {
        draft.selectedPostIndex = Math.min(
          draft.selectedPostIndex + 1,
          draft.posts.length - 1
        );
      })
    );
    trackAction(
      'Next Post Clicked',
      postModalData.posts[postModalData.selectedPostIndex].id
    );
  };

  const previousPostClickHandler = () => {
    setPostModalData(
      produce(postModalData, (draft) => {
        draft.selectedPostIndex = Math.max(draft.selectedPostIndex - 1, 0);
      })
    );
    trackAction(
      'Previous Post Clicked',
      postModalData.posts[postModalData.selectedPostIndex].id
    );
  };

  const showModalHandler = (data: PostModalData) => {
    setPostModalData(data);
    setShowModal(true);
  };

  const handleOnTrackingChanged = (isTracking: boolean) => {
    setLiveTrackingProxy(isTracking);
    trackAction('Live Tracking Changed', isTracking ? 'On' : 'Off');
  };

  const handleOnVettingStatusChange = (status: string) => {
    setVettingStatusProxy(status);
    trackAction('Vetting Status Changed', status);
  };

  const handleOnFlaggedPostClick = (flag: string, postId: string) => {
    const category = vettingCategories.flatFlaggedWithCategory.find(
      (flagged) => flagged.id === flag
    );
    const posts = category?.posts || [];
    const postIndex = posts?.findIndex((post) => post.id === postId);

    if (posts && postIndex > -1) {
      showModalHandler({
        posts,
        flag,
        selectedPostIndex: postIndex,
      });
      trackAction('Open Flagged Post Modal', postId);
    }
  };

  const handleOnFailedPostClick = (postId: string) => {
    const {posts} = vettingCategories.failed;
    const postIndex = posts.findIndex((post) => post.id === postId);
    if (posts && postIndex > -1) {
      // TODO: show modal
      trackAction('Open Failed Post Modal', postId);
    }
  };

  useEffect(() => {
    creatorVettingReportActions.initializeReportSettings(reportId);
  }, []);

  return (
    <div className={styles.creatorVettingReportContainer} data-testid={testID}>
      <div className={styles.creatorVettingReportContent}>
        <Conditional condition={showBackButton}>
          <ProfileVettingBreadcrumbs />
        </Conditional>
        <div className={styles.creatorVettingReportWrapper}>
          <div className={styles.sideBarContainer}>
            <ProfileVettingInfo
              profileImageUrl={vettingReport.thumbnailUrl}
              profileDisplayName={vettingReport.creatorName}
              showProfileInfo={showProfileInfo}
              vettingStatus={vettingStatusProxy}
              onVettingStatusChanged={handleOnVettingStatusChange}
            />
            <span>
              <Divider
                margin={0}
                color={designSystemToken('semantic.bg.neutral-inverse')}
              />
            </span>
            <ProfileVettingTracking
              isTracking={liveTrackingProxy}
              onTrackingChanged={handleOnTrackingChanged}
              timeRange={vettingReport.timeRange}
            />
            <span>
              <Divider
                margin={0}
                color={designSystemToken('semantic.bg.neutral-inverse')}
              />
            </span>
            <ScannedPlatforms
              platforms={vettingReport.platforms}
              trackAction={trackAction}
            />
            <Conditional condition={showProfileInfo}>
              <div className={styles.profileLinkContainer}>
                <ProfileLink
                  profileId={vettingReport.creatorId}
                  onClick={() => trackAction('Go to full profile', '')}
                />
              </div>
            </Conditional>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.vettingSummaryContainer}>
              <ProfileVettingItemsScanned
                totalItemsScanned={postAnalysesStats.totalPosts}
                totalVideoMinutesScanned={
                  postAnalysesStats.totalVideoDurationInSec
                    ? Math.round(postAnalysesStats.totalVideoDurationInSec / 60)
                    : 0
                }
                totalImagesScanned={postAnalysesStats.totalImages}
              />
              <ProfileVettingItemsFlagged
                totalItemsScanned={postAnalysesStats.totalPosts}
                totalItemsFlagged={vettingReport.flaggedPostAnalyses.length}
                vettingCategories={vettingCategories}
                trackAction={trackAction}
              />
              <ProfileVettingSummary summary={vettingReport.summary.summary} />
            </div>
            <div className={styles.onlineControversyContainer}>
              <SectionTitle
                title={translate(
                  `${TRANSLATION_PREFIX}.background-checks-title`
                )}
              />
              <div>TODO: Online Controversy</div>
            </div>
            <div className={styles.flaggedContentContainer}>
              <SectionTitle
                title={translate(`${TRANSLATION_PREFIX}.flagged-content-title`)}
                actions={
                  <ProfileVettingActions
                    vettingFilters={vettingFilters}
                    onDateRangeChange={(newDateRange) => {
                      if (newDateRange.startDate && newDateRange.endDate) {
                        creatorVettingReportActions.setReportTimeRangeFilter(
                          reportId,
                          {
                            start: newDateRange.startDate,
                            end: newDateRange.endDate,
                          }
                        );
                        trackAction(
                          'Date Range Changed',
                          `${dayjs(newDateRange.startDate).format(
                            NUMERIC_DAY_MONTH_YEAR_FORMAT
                          )} => ${dayjs(newDateRange.endDate).format(
                            NUMERIC_DAY_MONTH_YEAR_FORMAT
                          )}`
                        );
                      }
                    }}
                    onSeverityChange={(severity) => {
                      creatorVettingReportActions.setReportSeverityFilter(
                        reportId,
                        severity.id as Severity
                      );
                      trackAction('Severity Changed', severity.id);
                    }}
                  />
                }
              />
              <ProfileVettingFlaggedContent
                flaggedContent={vettingCategories.flatFlaggedWithCategory}
                onPostClick={handleOnFlaggedPostClick}
                trackAction={trackAction}
              />
            </div>
            <Conditional condition={vettingCategories.failed.posts.length > 0}>
              <div className={styles.failedContentContainer}>
                <SectionTitle
                  title={
                    <div className={styles.failedContentTitle}>
                      <Headline size="xl">
                        {translate(
                          `${TRANSLATION_PREFIX}.failed-content-title`
                        )}
                      </Headline>
                      <Headline
                        size="xl"
                        color={designSystemToken('semantic.fg.secondary')}
                      >
                        ({vettingCategories.failed.posts.length})
                      </Headline>
                    </div>
                  }
                />
                <ProfileVettingFailedContent
                  failedContent={vettingCategories.failed}
                  onPostClick={handleOnFailedPostClick}
                  trackAction={trackAction}
                />
              </div>
            </Conditional>
          </div>
        </div>
      </div>
      <ModalNavigation
        open={showModal}
        onClose={() => {
          setShowModal(false);
          trackAction('Close Post Modal', '');
        }}
        onNext={
          postModalData?.selectedPostIndex < postModalData.posts.length - 1
            ? nextPostClickHandler
            : undefined
        }
        onPrevious={
          postModalData.selectedPostIndex > 0
            ? previousPostClickHandler
            : undefined
        }
        title={
          <PostModalTitle
            currentIndex={postModalData.selectedPostIndex}
            totalItems={postModalData.posts.length}
            categoryName={camelCaseToDisplayName(postModalData.flag)}
          />
        }
      >
        <PostModalContent
          post={postModalData.posts[postModalData.selectedPostIndex]}
          category={postModalData.flag}
        />
      </ModalNavigation>
    </div>
  );
}

export default CreatorVettingReport;
