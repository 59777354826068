import {omit, pickBy} from 'lodash';
import React from 'react';
import {Box} from '@mui/material';
import {
  designSystemToken,
  Headline,
  Label,
  Icon,
  Body,
  Tooltip,
} from '@lightricks/react-design-system';
import camelCaseToDisplayName from '@/utils/camelCaseToDisplayName';
import translate from '@/utils/translate';
import {BrandSafetyAnalysis, PostAnalysis} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import Divider from '@/components/divider';
import GradientProgressBar from '@/components/gradient-progress-bar';
import InfoTooltip from '@/components/info-tooltip';
import styles from './AnalysisPanel.module.scss';

export interface AnalysisPanelProps {
  testID?: string;
  post: PostAnalysis;
  category: string;
}

function filterBrandSafetyCategories(brandSafetyAnalysis: BrandSafetyAnalysis) {
  const analysisWithoutSummary = omit(brandSafetyAnalysis, ['summary']);
  const relevantFlags = pickBy(
    analysisWithoutSummary,
    (value) => !!value.score && value.score > 0
  );
  return relevantFlags;
}

function FlagRow(props: {category: string; decimalScore: number}) {
  const {category, decimalScore} = props;
  const categoryDisplay = camelCaseToDisplayName(category);
  return (
    <Box className={styles.row}>
      <Body size="md" className={styles.flagTitle}>
        {categoryDisplay}
      </Body>
      <GradientProgressBar value={Math.ceil(decimalScore * 100)} />
    </Box>
  );
}

function FlaggedContent(props: {post: PostAnalysis; category: string}) {
  const {post, category} = props;
  const analysis = post.mediaAnalysisResult.brandSafetyAnalysis;

  const flaggedCategories = filterBrandSafetyCategories(analysis);
  const sortedOtherCategories = Object.keys(flaggedCategories)
    .filter((cat) => cat !== category)
    .sort((a, b) => {
      return flaggedCategories[b].score - flaggedCategories[a].score;
    });

  return (
    <Box className={`${styles.flaggedContentContainer} ${styles.section}`}>
      <Headline size="md">{translate(`flagged-content`)}</Headline>
      <Box className={styles.flaggedContentList}>
        <Box className={styles.row}>
          <Label size="lg">{translate(`category`)}</Label>
          <Box className={styles.categoryTitle}>
            <Label size="lg">{translate(`risk-level`)}</Label>
            <InfoTooltip title={translate('risk-level-tooltip')} />
          </Box>
        </Box>
        <FlagRow
          category={category}
          decimalScore={flaggedCategories[category].score}
        />
        <Conditional condition={sortedOtherCategories.length > 0}>
          <>
            <Box className={`${styles.row} ${styles.dividerContainer}`}>
              <Divider margin={0} />
              <Body size="sm">{translate(`also-found-in`)}</Body>
              <Divider margin={0} />
            </Box>
            {sortedOtherCategories.map((flaggedCategory) => (
              <FlagRow
                key={flaggedCategory}
                category={flaggedCategory}
                decimalScore={flaggedCategories[flaggedCategory].score}
              />
            ))}
          </>
        </Conditional>
      </Box>
    </Box>
  );
}

function AnalysisPanel(props: AnalysisPanelProps) {
  const {testID = 'analysis-panel', post, category} = props;

  return (
    <Box className={styles.mainContainer} data-testid={testID}>
      <FlaggedContent post={post} category={category} />
      <Box className={styles.section}>
        <Headline size="md">{translate(`summary`)}</Headline>
        <Body size="md">
          {post.mediaAnalysisResult.brandSafetyAnalysis.summary}
        </Body>
      </Box>
    </Box>
  );
}

export default AnalysisPanel;
